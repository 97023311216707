import { createLogic } from "redux-logic";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
// import adminActions from "store/admin/actions";
import authActions from "src/store/auth/actions";
import storage from "src/services/storage";
import jwtDecoder from "jwt-decode";
import * as moment from "moment";
import AsyncLock from "async-lock";

const normalizeActionName = (actionName) => {
  if (actionName == undefined) {
    console.error("action is not correct passed to logic generator");
  }
  return actionName
    .toLowerCase()
    .split("/")
    .pop()
    .split("_")
    .map((a, i) => (i > 0 ? a.charAt(0).toUpperCase() + a.substring(1) : a))
    .join("");
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 *
 * @param {String} apiNamespace api namespace
 * @param {String{}} options options which contain all options ,
 * @param {String} options.actionName => action name which will be executed,
 * @param {Function} options.successCb => custom success callback for more custom logic,
 * @param {Function} options.failCb => custom failure call back for custom error logic,
 * @param {String} options.successMessage => success message that will show on toastr when action successeded ,
 * @param {String} options.errorMessage => error message that will show on toastr when action failed,
 * @param {Boolean} options.showSuccessMessage => boolean if you have a custom success message by default false,
 * @param {Boolean} options.showErrorMessage => boolean if you have a custom error message by default false,
 *
 */
const appApi = {};
var lock = new AsyncLock();
export const logic = (apiNamespace, options = {}) => {
  const {
    actionName,
    successCb,
    failCb,
    successMessage,
    errorMessage,
    showSuccessMessage,
    showErrorMessage,
  } = options;
  const api_name = normalizeActionName(actionName);

  const logic = createLogic({
    type: actionName,
    latest: true,

    async process({ getState, action, api }, dispatch, done) {
      try {
        if (!appApi.api) {
          appApi.api = api;
          appApi.logout=() => dispatch(authActions.logout())
        }

        _validateApi(api, apiNamespace, api_name, action);
        // console.log("calling api",apiNamespace,api_name)
        lock.acquire("refreshToken", (done) => {
          refreshToken({ ...appApi });
          done();

        })
        // console.log("enter",apiNamespace,api_name)
        let request = {};

        let successCallBack = action?.payload?.successCallBack || null;
        let failCallBack = action?.payload?.failCallBack || null;

        if (action?.payload) {
          if (action.payload?.successCallBack) {
            const { successCallBack, ...rest } = action?.payload;
            request = rest;
          } else {
            request = action?.payload;
          }
        }

        const res = await api[apiNamespace][api_name](request);
        let requestId = res.data.requestId;
        let response = res.data?.payload || null;
        if (typeof (response) == "object" && !Array.isArray(response) && requestId) {
          response = { ...response, requestId };
        }
        if (!res.ok) {
          if (res.status == 401) {
            const token = "jwtToken";
            // const currentToken = localStorage.getItem(token);
            // if (currentToken) localStorage.removeItem(token);
            // dispatch(adminActions.reset());
            dispatch(authActions.logout());
            dispatch(push("/"));
          } else {
            const _errorMsg =
              res.data &&
              (res.data.errors ||
                res.data.errorMessage ||
                res.data.errorMessage);
            ("Unknown Error");
            dispatch({
              type: `${actionName}_FAIL`,
              payload: {
                errors: _errorMsg,
                codeNumber: res.data.codeNumber,
                requestId,
              },
              error: true,
            });
            failCallBack && failCallBack({ ...res.data });

            if (failCb) {
              failCb(dispatch, { ...res.data });
            }
            if (showErrorMessage) {
              if (errorMessage) toast.error(errorMessage);
              else {
                if (res.data && res.data.errorCode == "404") {
                  toast.error("No data found");
                } else {
                  toast.error(res.data.errorMessage);
                }
              }
            }
          }
        } else {
          successCallBack &&
            successCallBack(response);

          dispatch({
            type: `${actionName}_SUCCESS`,
            payload: response,
          });

          successCb &&
            successCb(dispatch, response);
          if (showSuccessMessage) toast.success(successMessage);
        }
      } catch (err) {
        console.error("Unhandled error in logic ", err);
        dispatch({ type: `${actionName}_FAIL`, payload: err, error: true });
        failCb && failCb(dispatch);
      }
      done();
    },
  });
  return logic;
};

const checking = {
  loading: false
};
setInterval(async () => {
  try {
    await refreshToken({ ...appApi })
  } catch (error) {

    console.log("failed checking token will try again ")
  } finally {
    checking.loading = false;
  }

}, 5000);

const refreshToken = async ({ api, logout }) => {
  // console.log("starting check token ")
  try {
    if (!!checking.loading) {
      // console.log("other call is running and checking the token expiry..")
      return;
    }
    checking.loading = true;
    if (!api) return;
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const decoder = jwtDecoder(token);
      // console.log(decoder.exp)
      if ((decoder.exp-10) < moment().unix()) {
        // console.log("starting refreshing the token ...", new Date().toTimeString())
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await api["auth"]["refreshToken"]({
          token: token,
          refreshToken: refreshToken,
        });
        if (response.ok) {
          const payload = response.data.payload;
          localStorage.setItem("jwtToken", payload.token);
          localStorage.setItem("refreshToken", payload.refreshToken);
        }
        else {
          logout()

        }
      }
    }
    checking.loading = false;
  } catch (error) {
    console.log("failed checking token will try again ")
  } finally {
    checking.loading = false;
    // console.log("cheking refresh token done ")
  }
};
function _validateApi(api, apiNamespace, api_name, action) {
  //todo: validatte api
  const func = api[apiNamespace][api_name];
  // console.log("res",api_name,apiNamespace)
  if (!func) {
  }
}

export default logic;
